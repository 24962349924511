<template>
    <div>
        <el-table stripe v-loading="loading" :data="dataList" >
            <el-table-column label="商户编号" prop="merchantNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="商户名称" prop="merSubName" min-width="200" :show-overflow-tooltip="true"/>
            <el-table-column label="活动名称" prop="activityName" min-width="200" :show-overflow-tooltip="true"/>
            <el-table-column label="活动类型" prop="activityType" min-width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">{{scope.row.activityType | merActivityTypeFM}}</template>
            </el-table-column>
            <el-table-column label="一级代理商" prop="oneAgentNo" min-width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.oneAgentNo}} - {{scope.row.oneAgentName}}
                </template>
            </el-table-column>
            <el-table-column label="直属代理商" prop="agentNo" min-width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.agentNo}} - {{scope.row.agentName}}
                </template>
            </el-table-column>
            <el-table-column label="SN号" prop="sn" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="机具型号" prop="hardwareName" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="绑定时间" prop="bindTime" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="目标金额" prop="targetAmount" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="已交易金额" prop="totalAmount" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="考核开始时间" prop="startTime" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="考核结束时间" prop="endTime" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="考核剩余天数" prop="lastDays" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="操作类型" prop="operationType" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">{{scope.row.operationType | operationTypeFM}}</template>
            </el-table-column>
            <el-table-column label="操作金额" prop="operationAmount" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="达标状态" prop="targetStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-button :type="scope.row.targetStatus=='init'?'primary':scope.row.targetStatus=='fail'?'danger':'success'" 
                    plain size="mini" class="button-status" v-if="scope.row.targetStatus">{{scope.row.targetStatus | targetStatusFM}}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="入账状态" prop="accountStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-button :type="scope.row.accountStatus=='success'?'success':'primary'" 
                    plain size="mini" class="button-status" v-if="scope.row.accountStatus">{{scope.row.accountStatus | accountStatusFM}}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="入账时间" prop="accountTime" min-width="160" :show-overflow-tooltip="true"/>
        </el-table>

        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
        <el-dialog
        title="操作对象"
        :visible.sync="showBox"
        width="50%"
        center>
        <template>
        <el-table stripe :data="levelList" style="width: 100%">
            <el-table-column label="身份">
                <template slot-scope="scope">
                    {{scope.row.userRole | userRoleFM}}
                </template>
            </el-table-column>
            <el-table-column prop="agentLevel" label="级别" />
            <el-table-column prop="userName" label="名称" />
            <el-table-column prop="agentNo" label="编号" />
            <el-table-column prop="actualAmount" label="金额" />
        </el-table>
        </template>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="closeShowBox">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
    import {ActivityApi} from '@/api';
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            queryParams: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                pageNo: 1,
                pageSize: 10,
                loading: false,
                total: 0,
                dataList: [],
                selectRow: {},
                showCustomForm: false,
                isAdd: false,
                showBox: false,
                levelList:''
            }
        },
        mounted() {
        },
        watch: {
            queryParams: function () {
                this.pageNo = 1;
                this.getList();
            },
        },
        methods: {
            async getList() {
                this.loading = true;
                let result = await ActivityApi.merchantActivity.queryPage(this.pageNo, this.pageSize, this.queryParams);
                this.loading = false;
                this.total = result.count || 0;
                this.dataList = result.data || [];
            },
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.getList();
            },
            handleStatusChange(row) {
                let text = row.status === "DISABLE" ? "禁用" : "启用";
                this.$confirm("是否确定"+text+"?","警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    let result = await ActivityApi.merchantActivity.edit(row.id, {
                        state: row.state
                    });
                    if (result.success) {
                        this.getList();
                    }
                }).catch(() => this.getList());
            },
            async handleUpdate(row) {
                this.showCustomForm = true;
                this.selectRow = row;
                this.isAdd = false;
            },
            handleDelete(row){
                this.$confirm("是否确定删除机具活动编号为"+row.id+"的数据项}?",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    let result = await ActivityApi.merchantActivity.remove(row.id);
                    if (result.success) {
                        this.Message.success("删除成功");
                        this.getList();
                    }
                });
            },
            async showDialog(row){
                let result = await ActivityApi.merchantActivity.querymerchantActivityDetail(row.id)
                if(result.success){
                    this.showBox = true
                    this.levelList = result.data
                }
            },
            closeShowBox(){
                this.showBox = false
                this.levelList = ''
            }
        }
    };
</script>

<style scoped>

</style>
